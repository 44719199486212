export const CAREER_TIMESPREAD_CONTENT = {
  workDate: '2021. 12. 28 ~ 2022. 12. 22',
  company: '넛지헬스케어',
  team: '타임스프레드 백엔드 팀',
  work: '타임스프레드 백엔드 팀에 합류하여 장애 조치, 코드 마이그레이션, 비용 최적화 업무를 담당했습니다.',
  workItems: [
    <>서비스 수익과 직결된 분당 <b>1,500 Request</b> 를 받는 퀴즈 서비스를 NestJS 로 구현하기 </>,
    <>다운 타임 <b>1시간 이내로 19억개</b>(240GB)의 값이 저장된 디비 테이블을 마이그레이션 하기 </>,
    <>한달 약 3천만원에 달하는 AWS 비용 줄이기</>,
    <>메모리 누수로 <b>32GB 이상의 메모리</b>를 먹는 서버 다이어트 시키기</>
  ]
}

export const CAREER_LINKAREER_CONTENT = {
  workDate: '2022. 11 ~ 2022. 03',
  company: '링커리어',
  team: '링커리어 백엔드 팀',
  work: '링커리어의 "자기소개서 작성" 서비스를 구현하고, 최적화 업무를 담당했습니다.',
  workItems: [
    <>복잡한 요구사항이 섞인 자기소개서 서비스 기획을 이해하고 GraphQL 쿼리로 풀어내기</>,
    <>MySQL FULLTEXT INDEX 와 ElasticSearch 등을 비교해보고 적절한 검색 엔진 찾기</>,
    <>특정 쿼리를 수행하면 서버가 다운되는 문제의 쿼리 찾고 수정하기</>
  ]
}