export const DEVOPS_SKILL_CONTENT = {
  "Git": {
    date: "2016년 08월",
    content: <>
      &nbsp;&nbsp;중학생 때, 자바로 흥미로운 프로그램을 개발했지만, 누군가에게 이 프로그램을 공유할 방법이 마땅히 없었습니다. 마침 구글에 파일 공유 사이트를 검색해보니 '깃허브'라는 서비스가 있어 가입하고 프로그램을 올린 게 제 깃허브 역사의 시작입니다.
      <br />&nbsp;&nbsp;지금은 학교 수업을 듣고, 친구들과 코드를 공유하면서 Git과 친해졌으며 회사 협업에서 매우 자주 쓰이기 때문에 더 이상 '파일공유 사이트'로 사용하지 않고 있습니다.
    </>
  },
  "Github Action": {
    date: "2022년 08월",
    content: <>
      &nbsp;&nbsp;개발한 소프트웨어를 누군가에게 보여주기 위해서는 서버에 올리는 `배포`작업이 필요합니다. 그동안은 하루에 적게는 두세 번, 많게는 열 번이 넘게 배포를 반복해야 하는 번거로움이 있었습니다.
      회사에서 이 기능을 알게 된 이후, 별도의 복잡한 설정 없이 깃허브에 코드를 푸시하면 테스트 및 배포 과정을 자동으로 진행해주는 깃허브 액션에 관심을 가지고 이용하게 되었습니다.
    </>
  },
  "Docker": {
    date: "2021년 05월",
    content: <>
      &nbsp;&nbsp;소프트웨어가 개발되는 환경과 실행되는 환경이 다른 경우가 매우 많습니다. 대표적으로 Windows 운영체제에서 개발했을 때 문제가 없었지만, Linux 계열의 서버 환경에서는 동작하지 않는 경우가 있습니다.
      도커는 소프트웨어를 '컨테이너'라는 독립적인 환경에 담아 항상 같은 실행 결과가 도출되도록 보장해주는 역할을 합니다. 환경 차이에서 발생하는 버그를 없앨 수 있었고, 컨테이너 단위로 프로그램을 관리 할 수 있어 편리했습니다.
    </>
  },
  "AWS": {
    date: "2022년 01월",
    content: <>
      &nbsp;&nbsp;사내 서비스 '타임스프레드'의 백엔드 서버 구축을 위해 AWS를 학습했습니다.
      Beanstalk을 이용해 서버를 실행했고, RDS, ElastiCache 등을 이용해 데이터를 저장했습니다. 일부 배치 작업을 위해 Lambda, Codebuild 를 사용했습니다.
    </>
  },
  "Azure": {
    date: "2023년 06월",
    content: <>
      &nbsp;&nbsp;AWS와 어떤 차이점이 있을지 궁금하고, 대학교에서 매년 크래딧을 지급하고 있어 클라우드를 제대로 공부해보기 위해 시작했습니다.
      개인 NAS 에서 동작하던 프로젝트를 일부 Azure 로 옮겼고, 추후 AZ-104등 자격증 취득까지 목표로 하고 있습니다.
    </>
  }
}