export const FRONTEND_SKILL_CONTENT = {
  "HTML/CSS": {
    date: "2014년 12월",
    content: <>
      &nbsp;&nbsp;초등학교 6학년 때, Linux 기반의 운영체제에서 마인크래프트 게임 서버를 운영했습니다. 하지만 적은 돈으로 무거운 게임 서버를 실행하다 보니 '감자 서버'라는 별명이 붙기도 했습니다.
      따라서, 서버 장애 발생 시 플레이어에게 사실을 공지할만한 홈페이지가 필요했습니다. IT 과목 선생님께 홈페이지를 어떻게 만드는지 여쭤보자, HTML, CSS를 소개해주셨고, 제 프로그래밍의 첫걸음이 되었습니다.
    </>
  },
  "React": {
    date: "2020년 10월",
    content: <>
      &nbsp;&nbsp;웹 사이트에서, 클라이언트가 API를 요청하고 값에 따라 DOM을 수동으로 갱신하는 기존 방법은 서비스 구성에 한계가 있었습니다. 특히 자바스크립트 코드에 HTML 코드가 섞이는 등 복잡도가 매우 증가했습니다.
      <br />&nbsp;&nbsp;리액트는 상태를 선언하면 상태에 따라 화면이 갱신되는 `선언형 뷰` 패러다임을 제시했고, 이 문제를 해결하는 데 큰 도움이 될 수 있을 거라고 생각해 배우게 되었습니다.
    </>
  },
  "React Native": {
    date: "2020년 11월",
    content: <>
      &nbsp;&nbsp;리액트 네이티브는 리액트를 바탕으로 안드로이드, 아이폰, Windows 앱 등 다양한 플랫폼에서 작동하는 앱을 개발할 수 있도록 도와주는 크로스 플랫폼 프레임워크입니다.
      주로 간단한 앱 개발이 필요할 때 Expo, react-native-cli 등을 규모에 맞게 선택하여 응용하고 있습니다.
    </>
  },
  "Flutter": {
    date: "2023년 11월",
    content: <>
      &nbsp;&nbsp;React Native는 커뮤니티에 의해 관리되는 프로젝트입니다.
      구글에 의해 관리되는 플러터에 비해 신규 API 등의 지원이 느릴 수 있고, 상대적으로 지원이 부족 할 수 있습니다. 또한 최근 트렌드가 플러터로 넘어가고 있음을 느꼈습니다.
      <br />따라서, 흥미 등 다양한 이유로 리엑트 네이티브에서 플러터로 기술을 변경해 보고 있습니다.
    </>
  },
}