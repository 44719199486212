const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Fwaterflake%2Fbackground.png?alt=media&token=378738a9-d38c-4d60-9690-e124c1845fd5";

const WATERFLAKE_CONTENT = {
  backgroundImage: backgroundImage,
  title: "WATERFLAKE",
  subtitle: <>플러그인 설치로 마인크래프트 도메인 서버 열기</>,
  skills: ['NestJS', 'React'],
  sliderImage: [
    backgroundImage,
    "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Fwaterflake%2F1.png?alt=media&token=380bb5e8-c14a-4b23-9569-2b58c998e72c",
    "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Fwaterflake%2F2.png?alt=media&token=107e760b-c4bb-4299-aaa7-cfb4d776c0f5",
    "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Fwaterflake%2F3.png?alt=media&token=5cc3e585-5f42-4ba7-a3c8-1b97f1c0cc4c",
    "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Fwaterflake%2F4.png?alt=media&token=f1bd1721-ab41-40a1-8c27-4a785baaf456",
    "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Fwaterflake%2F5.png?alt=media&token=048d2102-3037-42b6-bd2b-3305a528cd1b",
    "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Fwaterflake%2F6.png?alt=media&token=e52ce357-b179-441e-9f8a-63eb61bf7040",
  ],
  mainFeatures: [
    '터널링을 통해 비공개된 게임 서버를 도메인으로 공개',
    '서버 플러그인 - WATERFLAKE 서버 - 플레이어 간 양방향 터널링',
    '플러그인 설치만으로 쉽게 마인크래프트 도메인 서버 구축 가능'
  ],
  overview: [
    "참가 인원: 개인",
    "담당 작업: 전체",
    "개발 기간: 2023년 3월 19일 ~ 5월 1일",
  ],
  url: [
    {
      title: '📝 API 서버: ', url: 'https://github.com/bb2002/waterflake-api',
    },
    {
      title: '📝 Tunnel 서버: ', url: 'https://github.com/bb2002/waterflake-tunnel',
    },
    {
      title: '📝 Front: ', url: 'https://github.com/bb2002/waterflake-front',
    },
    {
      title: '📝 마인크래프트 플러그인: ', url: 'https://github.com/bb2002/waterflake-plugin',
    },
  ],
  feature: <>
    &nbsp;&nbsp;Waterflake 의 주요 기능은, 내부망에서 열려 외부에서 연결 불가능한 서버를 TCP 터널링을 통해 외부에서 연결 할 수 있도록 도와줍니다.
    마인크래프트 플러그인을 설치하고, 설정을 변경하면 로컬 PC와 Waterflake Tunnel 서버와 TCP 터널이 만들어지고, 이는 도메인을 통해 연결 가능하도록 구성됩니다.
  </>,
  motive: <>
    &nbsp;&nbsp;ngrok 이라는 터널링 서비스를 개선하여 마인크래프트 등 게임 서버에 적용하면 어떨까? 라는 아이디어에서 시작했습니다. 실제로 일부 유저들이 테스트 서버를 만들어주었고, 간단히 개인 PC 에서 마인크래프트 서버를 열 수 있어 반응이 좋았습니다.
    <br />&nbsp;&nbsp;하지만, ngrok 등 타 서비스와 비교하여 안정성이 상대적으로 불안정했고, 타겟팅한 유저들은 Minecraft Relam 이라는 서비스를 이용했기 때문에, 신규 유입이 많지 않았습니다. 또한 1.5.2 시절보다 개인 서버를 열고자하는 유저가 많이 줄어 카페나 블로그에 홍보 포스팅을 올렸을 때, 많은 사람들이 흥미를 가지지 않았습니다.
  </>,
  feel: <>
    &nbsp;&nbsp;TCP 터널링을 Java와 Node 서버를 이용해 구현해보니 매우 흥미로웠습니다. 특히 소켓의 특성상 연결 후 종료 (게임 서버 접속후 종료)하는 경우, 이를 폐기하고, 새로 연결을 맺는 생명주기를 자바 플러그인과 node 서버가 잘 관리해야하는데, 이를 구현하는데 어려움이 있었습니다.
    <br />&nbsp;&nbsp;비록 몇 주간의 운영 후 폐기된 프로젝트지만, 소켓 프로그래밍이라는 흔히 하기 어려운 경험을 할 수 있어 좋았습니다.
  </>
}

export default WATERFLAKE_CONTENT;