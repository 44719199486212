const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Ftaikocontest%2Fbackground.png?alt=media&token=e55d22c6-c966-486b-8bc1-3880bd36024d";

const TAIKO_CONTEST_CONTENT = {
  backgroundImage,
  title: 'GROOVE CHALLENGE',
  subtitle: '리듬게임 대회 개최 웹 서비스',
  skills: ['AWS', 'Serverless'],
  sliderImage: [
    backgroundImage,
    "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Ftaikocontest%2F1.png?alt=media&token=e2156a7c-dfc8-4eaa-9b77-300c0b0a85a3",
    "https://firebasestorage.googleapis.com/v0/b/ballbot-portfolio2.appspot.com/o/projects%2Ftaikocontest%2F2.png?alt=media&token=58e09e91-989d-4e73-ba2c-f44486d11c67"
  ],
  mainFeatures: [
    "태고 대회를 개최하고, 플레이어 점수를 자동으로 기록해주는 서비스",
    "AWS Lambda, DynamoDB 를 이용해 Serverless 한 서비스 구축",
  ],
  overview: [
    "참가 인원: 3인",
    "담당 작업: 백엔드 개발",
    "개발 기간: 2023년 11월 ~",
  ],
  url: [],
  feature: <>
    &nbsp;&nbsp;이번 프로젝트는 Serverless Framework 와 완전 관리형 데이터베이스 DynamoDB만을 이용해 서비스를 만드는게 목표입니다.
    여러 API를 서버리스하게 구현중이며, 추후 프론트 작업이 완료되면 Vercel 서비스를 사용해 서버 관리를 전혀 할 필요 없도록 만들려고 합니다.
  </>,
  motive: <>
    &nbsp;&nbsp;태고의 달인이라는 게임을 좋아하는 친구가 같이 만들자고 해 시작한 프로젝트 입니다. 간단한 사이드 프로젝트이며, 이 프로젝트에는 완전한 Serverless 구현을 목표로 개발해보고 있습니다.
  </>,
  feel: <>
    &nbsp;&nbsp;서버리스 특성상 Nest 같은 익숙한 프레임워크를 이용하기 어렵습니다. 수 밀리초 내로 서버가 실행되어야 하기 때문에, 최대한 가볍게 서버를 만들어야 합니다.
    이를 위해 Guard, Service, Controller 등 레이어 분리를 하나의 함수에 몰아 최소한의 코드만으로 동작할 수 있도록 공통 함수를 만들었고, 오래 걸리는 작업은 배치작업으로 옮겼습니다.
    <br />현재는 개발중이며, 추후 완성되면 일정 규모 까지는 큰 비용 절약을 달성 할 수 있을것으로 예상됩니다.
  </>
}

export default TAIKO_CONTEST_CONTENT;